import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Article from "../article"
import Container from "../container"
import { SectionA, Title2, styles } from "../styles"
import Grid from "../grid"
import Fade from "react-reveal/Fade"

const ArticlesContainer = styled(Container)`
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 600px) {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 768px) {
    max-width: 90%;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 1366px) {
    max-width: 1270px;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const ArticleGrid = styled(Grid)`
  .react-reveal {
    &:nth-child(1) {
      animation-delay: 300ms !important;
    }

    &:nth-child(2) {
      animation-delay: 300ms !important;
    }

    &:nth-child(3) {
      animation-delay: 300ms !important;
    }

    @media (min-width: 768px) {
      &:nth-child(2) {
        animation-delay: 500ms !important;
      }

      &:nth-child(3) {
        animation-delay: 700ms !important;
      }
    }
  }
`

const LastArticles = () => {
  const lastArticlesQuery = useStaticQuery(graphql`
    query lastArticlesQuery {
      allWordpressPost(limit: 3) {
        nodes {
          categories {
            id
            name
            slug
          }
          id
          slug
          date
          title
          acf {
            artykul
            zajawka
          }
          featured_media {
            title
          }
        }
      }
    }
  `)

  return (
    <SectionA id="aktualnosci" p={"40px 0"}>
      <ArticlesContainer>
        <Fade>
          <Title2 color={styles.colorSecondary} mb={"34px"}>
            Aktualności
          </Title2>
        </Fade>
        <ArticleGrid
          className={"articleGrid"}
          xs={"1fr"}
          lg={"50% 50%"}
          xl={"33.33% 33.33% 33.33%"}
          columnGap={"0"}
        >
          {lastArticlesQuery.allWordpressPost.nodes.map(item => (
            <Fade>
              <Article
                date={item.date}
                title={item.title}
                short={item.acf.zajawka}
                excerpt={item.acf.artykul}
                slug={item.slug}
                social={"facebook"}
                categories={item.categories.map(category => category.name)}
                categoriesSlug={item.categories.map(category => category.slug)}
                image={
                  item.featured_media ? item.featured_media.title : "Header"
                }
              />
            </Fade>
          ))}
        </ArticleGrid>
      </ArticlesContainer>
    </SectionA>
  )
}

export default LastArticles
