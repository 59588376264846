import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { ThemeConsumer } from "styled-components"
import Image from "../image"
import Container from "../container"
import { SectionA, FlexBox, Title2, Text, Button, styles } from "../styles"
import Fade from "react-reveal/Fade"
import parse from "html-react-parser"
import { hyphenate, hyphenateSync } from "hyphen/pl"

const Content = styled(FlexBox)`
  width: 100%;
  padding-top: 26px;
  padding-bottom: 26px;

  @media (min-width: 768px) {
    width: 58.33%;
    padding-right: 58px;
  }
`

const TextBox = styled.div`
  width: 100%;
  background-color: ${props =>
    props.theme === "dark" ? styles.colorDark : "rgba(193, 160, 72, 0.4)"};
  padding: 10px 12px;

  .react-reveal {
    animation-delay: 450ms !important;
  }

  p {
    margin-left: auto;
  }
`

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 84px;
  width: 100%;
  position: relative;
  padding: 0 25px;
  margin-bottom: 26px;

  &::before {
    content: "";
    height: 100%;
    width: 4px;
    background-color: ${props =>
      props.theme === "dark" ? styles.colorDark : styles.colorSecondary};
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  @media (min-width: 768px) {
    justify-content: flex-end;
    padding: 0;

    &::before {
      right: -25px;
    }
  }
`

const ImageBox = styled(FlexBox)`
  width: 100%;

  @media (min-width: 768px) {
    width: 41.66%;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 445px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
  }

  .react-reveal {
    animation-delay: 500ms !important;
  }
`

const ButtonsWrapper = styled(FlexBox)`
  width: 100%;

  @media (min-width: 768px) {
    margin-right: -20px;
  }

  .react-reveal {
    &:nth-child(1) {
      animation-delay: 600ms !important;
    }

    &:nth-child(2) {
      animation-delay: 800ms !important;
    }

    &:nth-child(3) {
      animation-delay: 1000ms !important;
    }
  }
`

const AboutSection = () => {
  const aboutQuery = useStaticQuery(graphql`
    query aboutQuery {
      allWordpressAcfPages(filter: { wordpress_id: { eq: 2 } }) {
        nodes {
          id
          acf {
            o_mnie_tytul
            o_mnie_tresc
            o_mnie_obrazek {
              title
            }
          }
        }
      }
    }
  `)

  const acf = aboutQuery.allWordpressAcfPages.nodes[0].acf
  const content = hyphenateSync(acf.o_mnie_tresc)

  return (
    <ThemeConsumer>
      {theme => (
        <SectionA id="o-kancelarii" p={"40px 0 26px"} pLG={"26px 0"}>
          <Container>
            <FlexBox direction={"column"} directionMD={"row"} align="center">
              <Content
                direction={"column"}
                justify="space-between"
                alignMD={"flex-end"}
              >
                <TitleBox theme={theme.mode}>
                  <Fade>
                    <Title2>{acf.o_mnie_tytul}</Title2>
                  </Fade>
                </TitleBox>
                <TextBox theme={theme.mode}>
                  <Fade>
                    <Text
                      theme={theme}
                      align={"center"}
                      alignMD={"justify"}
                      //maxWidthLG={"485px"}
                      mb={"25px"}
                      color={styles.colorGray}
                    >
                      {parse(content)}
                    </Text>
                  </Fade>
                </TextBox>
                <ButtonsWrapper justify={"center"} justifyMD={"flex-end"}>
                  <Fade>
                    <Button to={"#uslugi-prawne"}>Usługi</Button>
                  </Fade>
                  <Fade>
                    <Button to={"#kontakt"} ml={"5px"}>
                      Kontakt
                    </Button>
                  </Fade>
                  <Fade>
                    <Button to={"blog"} ml={"5px"}>
                      Blog
                    </Button>
                  </Fade>
                </ButtonsWrapper>
              </Content>
              <ImageBox justify={"flex-end"}>
                <ImageWrapper>
                  <Fade>{checkImage(acf.o_mnie_obrazek)}</Fade>
                </ImageWrapper>
              </ImageBox>
            </FlexBox>
          </Container>
        </SectionA>
      )}
    </ThemeConsumer>
  )
}

export default AboutSection

function checkImage(image) {
  if (image.title !== "") {
    return <Image filename={image.title} />
  }
}
