import React from "react"
import styled from "styled-components";
import Container from "../container"
import { SectionA, FlexBox, Title2, styles } from "../styles"
import Form from "../form";

const ContactFormBox = styled(FlexBox)`
  width: 100%;
  padding-top: 50px;
  max-width: 642px;
`;

const ContactSection = () => {

  return(

    <SectionA id="kontakt" p={"40px 0"}>
      <Container>
      <Title2 color={styles.colorPrimary} mb={"52px"}>Formularz kontaktowy</Title2>
        <FlexBox justify="center">
          <ContactFormBox justify="center" justifyLG="flex-end">
            <Form />
          </ContactFormBox>
        </FlexBox>
      </Container>
    </SectionA>

  )
}

export default ContactSection
