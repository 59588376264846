import React from "react"
import styled from "styled-components"
import { FlexBox, Button, styles } from "./styles"
import FormAcceptance from "./form-acceptance"
import Fade from "react-reveal/Fade"

const Wrapper = styled.div`
  width: 100%;

  label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    &[for="email"] {
      margin-bottom: 19px;
    }

    &[for="topic"] {
      margin-bottom: 32px;
    }

    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: ${props => (props.theme.mode === "dark" ? "#fff" : "#000")};
      white-space: nowrap;
      padding-top: 5px;
      margin-bottom: 25px;

      @media (min-width: 992px) {
        margin-bottom: 0;
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px ${styles.colorSecondary} inset;
    }

    @media (min-width: 992px) {
      flex-direction: row;
    }
  }
`

const FormInput = styled.input`
  height: 28px;
  width: 100%;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid ${styles.colorSecondary};
  padding: 5px;
  color: ${props => (props.theme.mode === "dark" ? "#fff" : "#000")};

  &:hover,
  &:focus {
    border-bottom: 1px solid ${styles.colorPrimary};
    outline: none;
  }

  @media (min-width: 992px) {
    max-width: 480px;
  }
`

const FormTextarea = styled.textarea`
  height: 160px;
  width: 100%;
  background-color: transparent;
  border: 1px solid ${styles.colorSecondary};
  padding: 5px;
  resize: none;
  color: ${props => (props.theme.mode === "dark" ? "#fff" : "#000")};

  &:hover,
  &:focus {
    border: 1px solid ${styles.colorPrimary};
    outline: none;
  }

  @media (min-width: 576px) {
    height: 94px;
  }

  &::placeholder {
    color: ${props => (props.theme.mode === "dark" ? "#fff" : "#000")};
  }
`

const FormButton = styled.button`
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: ${styles.colorPrimary};
  border: 0;
  padding: 8px 20px;
  margin: 10px auto;
  cursor: pointer;
  transition: color 0.1s ease, background-color 0.3s ease;

  &:hover {
    color: ${styles.colorPrimary};
    background-color: ${styles.colorSecondary};

    svg {
      path {
        fill: #000;
      }
    }
  }
`

const Form = () => {
  return (
    <Wrapper>
      <form
        name="Contact"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input type="hidden" name="bot-field" />
        <FlexBox direction={"column"}>
          <Fade>
            <label htmlFor="email">
              <span>Twój adres e-mail:</span>
              <FormInput type="email" name="email" required />
            </label>
          </Fade>
          <Fade>
            <label htmlFor="topic">
              <span>Temat wiadomości:</span>
              <FormInput type="text" name="topic" />
            </label>
          </Fade>
        </FlexBox>
        <Fade>
          <label htmlFor="message" style={{ position: "relative" }}>
            <FormTextarea type="text" name="message" placeholder="..." />
          </label>
        </Fade>
        <Fade>
          <FormAcceptance />
        </Fade>
        <FlexBox justify={"center"} align={"center"}>
          <Fade>
            <FormButton type="submit">Wyślij</FormButton>
          </Fade>
        </FlexBox>
      </form>
    </Wrapper>
  )
}

export default Form
