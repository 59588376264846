import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import About from "../components/sections/about"
import Services from "../components/sections/services"
import Contact from "../components/sections/contact"
import BackgroundSection from "../components/sections/background-photo"
import Articles from "../components/sections/last-articles"
import SEO from "../components/seo"
import Hero from "../components/hero"

const IndexPage = () => {
  const query = useStaticQuery(graphql`
    query myQuery {
      allWordpressAcfPages(filter: { wordpress_id: { eq: 2 } }) {
        nodes {
          id
          acf {
            tlo_1_obraz {
              title
            }
            tlo_2_obraz {
              title
            }
          }
        }
      }
    }
  `)

  //const isSSR = typeof window === "undefined";
  const acf = query.allWordpressAcfPages.nodes[0].acf
  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <About />
      <Services />
      {/* <SectionB>
      <ParallaxPhoto />
    </SectionB> */}
      <BackgroundSection
        title={acf.tlo_1_obraz.title}
        p={"0 0 29px"}
        pMD={"84px 0 49px"}
      />
      <Articles />
      <BackgroundSection
        title={acf.tlo_2_obraz.title}
        position={"80%"}
        p={"0 0 49px"}
        pMD={"84px 0 49px"}
      />
      <Contact />
    </Layout>
  )
}
export default IndexPage
