import React from "react"
import { withStyles } from "@material-ui/core/styles"
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel"
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import { styles, Text } from "./styles"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import { hyphenate, hyphenateSync } from "hyphen/pl"
import Item from "./service-list-item"

const List = styled.div`
  width: 100%;
  padding-left: 15px;
  position: relative;

  .service-list {
    &__item {
      width: 100%;
    }

    &__item-name {
      padding: 1rem 0;
      margin: 0;
    }
  }

  @media (min-width: 600px) {
    padding-left: 85px;
  }

  &::after {
    content: "";
    height: 100%;
    width: 4px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${styles.colorSecondary};

    @media (min-width: 600px) {
      left: 40px;
    }

    @media (min-width: 992px) {
      left: 0;
    }
  }
`

class ServiceList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      serviceOpen: false,
      current: null,
    }
  }

  componentDidMount = () => {
    // document.querySelector(".service-list").style.height = `${
    //   document.querySelector(".service-list").clientHeight
    // }px`
    // document.querySelectorAll(".service-list__item").forEach(function(item) {
    //   item.style.top = `${item.offsetTop}px`
    // })
    // document.querySelectorAll(".service-list__item").forEach(function(item) {
    //   item.style.position = `absolute`
    // })
  }

  componentDidUpdate = () => {
    console.log("update")
    //console.log(document.querySelectorAll(".service-list__item"))
    // if (this.state.serviceOpen) {
    //   document.querySelectorAll(".service-list__item").forEach(function(item) {
    //     // item.style.display = "none"
    //     item.classList.remove("active")
    //   })
    // }
  }

  handleItemClick(e) {
    console.dir(e.target)
    console.log(this)
    console.log(e.target.dataset.id)
    console.log(this.isOpen)
    // this.setState(prevState => ({
    //   serviceOpen: !prevState.serviceOpen,
    // }))
    // if (this.state.serviceOpen) {
    if (e.target.classList.contains("service-list__item")) {
      if (e.target.classList.contains("active")) {
        e.target.classList.remove("active")
        document
          .querySelectorAll(".service-list__item")
          .forEach(function(item) {
            item.classList.remove("hidden")
          })
      } else {
        document
          .querySelectorAll(".service-list__item")
          .forEach(function(item) {
            // item.style.display = "none"
            if (item === e.target) {
              item.classList.add("active")
            } else {
              item.classList.remove("active")
              item.classList.add("hidden")
            }
          })
      }
    }

    // document.querySelectorAll(".service-list__item").forEach(function(item) {
    //   // item.style.display = "none"
    //   if (item === e.target) {
    //     if (item.classList.contains("active")) {
    //       item.classList.remove("active")
    //     } else {
    //       item.classList.add("active")
    //     }
    //   } else {
    //     item.classList.remove("active")
    //     item.classList.add("hidden")
    //   }
    // })
    //}

    this.setState({
      serviceOpen:
        this.state.current === e.target.dataset.id ? "false" : "true",
      current: e.target.dataset.id,
    })
  }

  render() {
    const { serviceOpen, current } = this.state
    const services = this.props.services
    console.log(services)
    return (
      <List
        services={services}
        className={serviceOpen ? "service-list active" : "service-list"}
      >
        {services.map((item, index) => {
          index += 1
          const title = item.nazwa_uslugi
          const content = item.opis_uslugi
          return (
            <Item
              className={"service-list__item"}
              onClick={e => this.handleItemClick(e)}
              dataId={"1"}
              current={current}
              title={title}
              content={content}
            />
          )
        })}
      </List>
    )
  }
}

export default ServiceList
// function template(i, duration) {
//   duration += 50 * i
//   return `
//         &:nth-child(${i + 1}) {
//           animation-delay: ${duration}ms !important;
//          }
//       `
// }
// const getAnimations = services => {
//   let str = ""
//   const duration = 300

//   for (let index = 0; index < services.length; index += 1) {
//     str += template(index, duration)
//   }
//   return str
// }
