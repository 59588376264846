import React from "react"
import { SectionB } from "../styles"
import styled from "styled-components"
import Image from "../image"

const ImageWrapper = styled.div`
  width: 100%;

  .gatsby-image-wrapper {
    height: 80vw;
    max-height: 350px;

    img {
      object-position: 0
        ${props => (props.position ? props.position : "center")} !important;
    }
  }

  @media (min-width: 992px) {
    .gatsby-image-wrapper {
      max-height: 550px;
    }
  }
`

const BackgroundSection = ({ title, position, p, pMD }) => {
  return (
    console.log(position),
    (
      <SectionB p={p} pMD={pMD}>
        <ImageWrapper position={position}>{checkImage(title)}</ImageWrapper>
      </SectionB>
    )
  )
}

export default BackgroundSection

function checkImage(image) {
  if (image !== "") {
    return <Image filename={image} />
  }
}
