import React from "react"
import styled from "styled-components"
import { Text, styles } from "./styles"
import Fade from "react-reveal/Fade"

const Wrapper = styled.div`
  width: 100%;
  padding-left: 15px;
  margin: 52px 0;
  border-left: 4px solid ${styles.colorSecondary};

  @media (min-width: 992px) {
    padding-left: 87px;
  }
`

const Checkmark = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid ${styles.colorSecondary};
  z-index: 0;
  transform: scale(1);
  vertical-align: middle;
  background-color: transparent;
  transition: all 0.2s ease;
  margin-right: 15px;
  cursor: pointer;

  svg {
    height: 17px;
    width: 17px;
    position: absolute;
    top: 1px;
    left: 1px;
    fill: none;
    stroke: ${styles.colorPrimary};
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16;
    stroke-dashoffset: 16;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
    z-index: 99;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
  }

  .active {
    background-color: transparent;
    animation: wave 0.4s ease;

    &::before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }
  }

  @media (min-width: 992px) {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    max-width: 20px;
    max-height: 20px;

    svg {
      height: 17px;
      width: 17px;
    }
  }
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;

  .checkbox:checked ~ .checkmark {
    background-color: transparent;
    animation: wave 0.4s ease;

    svg {
      stroke-dashoffset: 0;
    }

    &::before {
      transform: scale(3.5);
      opacity: 0;
      transition: all 0.6s ease;
    }
  }
`

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
`

class FormAcceptance extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      details: [{ id: 0, name: "checkbox", active: false }],
    }
  }

  handleClick = e => {
    e.target.closest("input").classList.toggle("active")
  }

  render() {
    return (
      <Wrapper>
        <Label>
          <Checkbox className={"checkbox"} type={"checkbox"} />
          <Checkmark className={"checkmark"}>
            <svg width="8px" height="8px" viewBox="-1 0 16 12">
              <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
            </svg>
          </Checkmark>
          <Text p={"0 0 0 55px"} mb={"32px"}>
            Zgadzam się na kontakt w celu uzyskania odpowiedzi na wiadomość
            zawartą w formularzu kontaktowym.
          </Text>
        </Label>
        <Text>
          Państwa dane przetwarzane będą wyłącznie w celu udzielenia odpowiedzi
          na zapytanie zgodnie z zasadą, która głosi, że przetwarzanie danych
          jest zgodne z prawem jeżeli jest niezbędne w celu realizacji umowy lub
          przed jej zawarciem.
        </Text>
      </Wrapper>
    )
  }
}

export default FormAcceptance
