import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled, { keyframes, ThemeConsumer } from "styled-components"
import { styles, FlexBox, Title1, Text } from "./styles"
import Container from "./container"
import SVG from "../images/annamilcarz-main-logo.inline.svg"
import Image from "./image"
import { transparentize } from "polished"
import { hyphenate, hyphenateSync } from "hyphen/pl"
import useTheme from "./use-theme"

const Fade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(25%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${styles.colorPrimary};
`

const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;

  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;

    img {
      object-position: 20% 0px !important;

      @media(min-width: 768px) {
        object-position: top center !important;
      }
    }
  }

  & > div {
    position: absolute !important;
    z-index: 0 !important;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    background-color: ${styles.colorPrimary};
    //background-color: ${props =>
      props.theme.mode === "dark" ? "#000" : styles.colorPrimary};
    //opacity: ${props => (props.theme.mode === "dark" ? ".51" : ".51")};
    opacity: .51;
    transition: opacity .3s ease;
  }
}
`

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vmin;
  //max-width: 400px;
  //max-width: 650px;
  //margin-top: calc(50vh - 160px);
  position: relative;
  padding: 15vh 0;
  width: 100%;
  z-index: 2;
  animation-name: ${Fade};
  animation-fill-mode: both;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
  animation-delay: 300ms;

  &::before,
  &::after {
    width: 4px;
    height: 11vh;
    max-height: 200px;
    background-color: ${props =>
      props.theme.mode === "dark" ? styles.colorDark : styles.colorSecondary};
    position: absolute;
    left: 50%;

    @media (min-width: 992px) {
      content: "";
    }
  }

  &::before {
    top: 0;
    transform: translateX(-50%);
  }

  &::after {
    bottom: 0;
    transform: translateX(-50%);
  }

  @media (min-height: 900px) {
    &::before,
    &::after {
      height: 15vh;
    }
  }

  svg {
    max-height: 150px;
    margin-top: 25px;
    margin-bottom: 45px;
  }
`

const Headline = styled.div`
  //align-self: flex-start;
  width: 100%;
  //max-width: 580px;
  //background-color: ${styles.colorPrimary};
  margin-top: auto;
  z-index: 2;

  p {
    font-weight: 900;
  }
`

const StrengthsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  p {
    font-size: 25px;
    text-transform: uppercase;
    padding-top: 25px;
    transition: color 0.3s ease;
    color: ${styles.colorSecondary};
  }
`

const Quote = styled.div`
  width: 100%;
  position: absolute;
  bottom: 7vh;
  transform: translateY(50%);
  margin-top: auto;
  margin-right: auto;
  z-index: 2;

  p {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50% - 10px);
    }

    @media (min-width: 992px) {
      width: 100%;
    }
  }
`

const Hero = () => {
  const backgroundquery = useStaticQuery(graphql`
    query background {
      allWordpressAcfPages(filter: { wordpress_id: { eq: 2 } }) {
        nodes {
          id
          acf {
            zdjecie_glowne_obraz {
              title
            }
          }
        }
      }
    }
  `)

  const media =
    backgroundquery.allWordpressAcfPages.nodes[0].acf.zdjecie_glowne_obraz
  const content = hyphenateSync(`Profesjonalizm, lata praktyki i doświadczenia pozwalają na
    świadczenie Państwu usług na najwyższym poziomie. Każdy przypadek
    wymaga indywidualnego podejścia, rzetelności i zaangażowania.
    Znajomość praw i ich wykorzystanie jest niepodważalnym atutem, który
    chcę zawsze przekuć w najlepsze możliwe rozwiązanie, zapewniając
    ze swojej strony:`)

  return (
    <ThemeConsumer>
      {theme => (
        <Wrapper className={"hero"}>
          <BackgroundWrapper>
            <Image filename={media.title} />
          </BackgroundWrapper>
          <FlexBox
            direction="column"
            align="center"
            justify="center"
            grow="1"
            width="100%"
          >
            <Logo className={"fadeAn"}>
              <SVG />
              <Title1 mb="15px">
                <span>Kancelaria radcy prawnego</span> Anna Milcarz
              </Title1>
              <Quote>
                <Container>
                  <Text color={"#fff"} maxWidth={"420px"} mb={"10px"}>
                    „Sprawiedliwość jest drugim wielkim zadaniem prawa, jednak
                    pierwszym jest pewność prawa, pokój.”
                  </Text>
                  <Text color={"#fff"} maxWidth={"420px"}>
                    Autor: Gustav Radbruch
                  </Text>
                </Container>
              </Quote>
            </Logo>
          </FlexBox>
          <Headline>
            <Container>
              <Text align={"justify"} color="#fff">
                {content}
              </Text>
              <StrengthsBox>
                <Text>Skuteczność</Text>
                <Text>Profesjonalizm</Text>
                <Text>Zaufanie</Text>
              </StrengthsBox>
            </Container>
          </Headline>
        </Wrapper>
      )}
    </ThemeConsumer>
  )
}

const colorChange = e => {
  console.log(e.target.classList)
  e.target.classList.add("active")
}

export default Hero
