import React from "react"
import styled from "styled-components"
import { styles } from "./styles"
import parse from "html-react-parser"

const ListItem = styled.a`
  display: block;
  text-decoration: none;
  margin: 0;
  border: 1px solid transparent;
  cursor: pointer;
  position: relative;
  border: 1px solid ${styles.colorSecondary};
  margin-bottom: 15px;

  .service-list {
    &__title {
      padding: 20px 40px 20px 12px;
      font-weight: 900;
      font-size: 15px;
      line-height: 20px;
      color: rgb(17, 30, 47);
      color: ${styles.colorPrimary};
      border: 1px solid transparent;
      transition: border-color 0.3s ease;
      pointer-events: none;
      text-transform: uppercase;
      position: relative;

      &::before {
        content: "więcej";
        position: absolute;
        right: 5px;
        bottom: 0px;
        color: ${styles.colorSecondary};
        font-size: 8px;
      }
    }

    &__content {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      height: 0;
      overflow: hidden;
      text-align: left;
      cursor: auto;

      p {
        background-color: ${props =>
          props.theme.mode === "dark"
            ? styles.colorPrimary
            : "rgba(193, 160, 72, 0.4)"};
        color: ${props =>
          props.theme.mode === "dark" ? "#fff" : "rgba(0, 0, 0, 0.8)"};
        margin: 0;
      }

      & > ul {
        background-color: ${props =>
          props.theme.mode === "dark"
            ? styles.colorPrimary
            : "rgba(193, 160, 72, 0.4)"};
        margin: 0;
        padding: 0 12px 10px 25px;

        li {
          color: ${props =>
            props.theme.mode === "dark" ? "#fff" : "rgba(0, 0, 0, 0.8)"};
        }

        ul {
          list-style: none;
          margin: 0;
          padding-top: 15px;
          padding-left: 15px;

          li {
            color: ${props =>
              props.theme.mode === "dark" ? "#fff" : "rgba(0, 0, 0, 0.8)"};
            text-indent: -15px;

            &::before {
              content: "- ";
              text-indent: -15px;
            }
          }
        }
      }

      .list-none {
        list-style: none;
      }
    }
  }

  &:hover {
    .service-list {
      &__title {
        background-color: ${styles.colorSecondary};
        color: #fff;

        &::before {
          color: #fff;
        }
      }
    }
  }

  &.active {
    height: 100%;
    border: 1px solid transparent;

    .service-list {
      &__title {
        border: 1px solid ${styles.colorSecondary};
        color: rgb(17, 30, 47);
        background-color: #fff;

        &::before {
          content: "zamknij";
          position: absolute;
          right: 5px;
          bottom: 0px;
          color: ${styles.colorSecondary};
          font-size: 8px;
        }
      }

      &__content {
        height: calc(100% - 60px);
        overflow: auto;

        p {
          padding: 10px 12px;
        }
      }
    }

    &:hover {
      border: 1px solid transparent;
    }
  }

  &.hidden {
    display: none;
  }
`

class ServiceListItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      hidden: false,
    }
  }

  handleClick = e => {
    // this.setState(prevState => ({
    //   isOpen: !prevState.isOpen,
    // }))
    console.log(e.target)
    console.log(e.target.dataset.id)
    console.log(this.props.current)
    if (e.target.classList.contains("service-list__item")) {
      this.setState({
        isOpen: e.target.dataId !== this.props.current ? true : false,
        hidden: false,
      })
      this.props.onClick(e)
    }
  }

  // componentDidUpdate = () => {
  //   console.log("button update")
  //   console.log(this.state.isOpen)
  //   console.log(this.props.current)
  //   console.log(this.props.dataId)
  //   if (
  //     this.state.isOpen !== false &&
  //     this.props.current !== this.props.dataId
  //   ) {
  //     this.setState({
  //       isOpen: false,
  //       hidden: true,
  //     })
  //   } else {
  //     this.setState({
  //       hidden: true,
  //     })
  //   }

  //   console.log(this)
  // }

  render() {
    const { isOpen, hidden } = this.state
    return !hidden ? (
      <ListItem
        href="#uslugi-prawne"
        className={
          isOpen ? `${this.props.className} active` : this.props.className
        }
        onClick={e => this.handleClick(e)}
        data-id={this.props.dataId}
      >
        <div className={"service-list__title"}>{this.props.title}</div>
        <div className={"service-list__content"}>
          {parse(this.props.content)}
        </div>
      </ListItem>
    ) : null
  }
}

export default ServiceListItem
