import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Image from "../image"
import Container from "../container"
import { SectionA, FlexBox, Title2, Text, styles } from "../styles"
import ServiceList from "../service-list-new"
import Fade from "react-reveal/Fade"
import Icon from "../../images/gavel.inline.svg"
import parse from "html-react-parser"
import { hyphenate, hyphenateSync } from "hyphen/pl"

const ContentBox = styled(FlexBox)`
  width: 100%;
  padding-bottom: 26px;

  @media (min-width: 992px) {
    width: 40vw;
  }

  @media (min-width: 1200px) {
    width: 50%;
  }
`

const Textbox = styled.div`
  position: relative;
  margin: 65px 0 35px;

  @media (min-width: 600px) {
    margin: 65px 0;
    padding-left: 115px;
  }

  @media (min-width: 992px) {
    padding-left: 75px;
  }
`

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 169px;
  width: 100%;
  background-color: ${styles.colorPrimary};
  position: relative;
  padding: 42px 76px;

  @media (min-width: 992px) {
    justify-content: flex-start;
    width: 65%;
  }
`

const IconBox = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  @media (min-width: 600px) {
    display: flex;
  }

  @media (min-width: 992px) {
    transform: translate(-50%, -50%);
  }

  svg {
    path {
      fill: ${styles.colorSecondary};
    }
  }
`

const ImageBox = styled(FlexBox)`
  display: none;
  width: 100%;
  height: 100%;
  // height: 80vw;
  // max-height: 1015px;

  @media (min-width: 992px) {
    display: flex;
    width: 45%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 650px;
  margin-left: auto;

  .gatsby-image-wrapper {
    height: 100%;

    img {
      object-position: top center !important;
      object-fit: none !important;

      @media (min-width: 1920px) {
        object-fit: none !important;
      }
    }
  }
`

const FullWidthContainer = styled(Container)`
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 600px) {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: 768px) {
    max-width: 90%;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 1366px) {
    max-width: 1270px;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const ServicesSection = () => {
  const servicesQuery = useStaticQuery(graphql`
    query servicesQuery {
      allWordpressAcfPages(filter: { wordpress_id: { eq: 2 } }) {
        nodes {
          id
          acf {
            oferta_tytul
            oferta_tresc
            oferta_obrazek {
              title
            }
            usluga {
              nazwa_uslugi
              opis_uslugi
            }
          }
        }
      }
    }
  `)

  const acf = servicesQuery.allWordpressAcfPages.nodes[0].acf
  const content = hyphenateSync(acf.oferta_tresc)

  return (
    <SectionA id="uslugi-prawne" p={"40px 0"}>
      <ImageBox justify={"flex-end"}>
        <ImageWrapper>
          <Image filename={acf.oferta_obrazek.title} />
        </ImageWrapper>
      </ImageBox>
      <FullWidthContainer>
        <FlexBox justify={"flex-end"} width={"100%"}>
          <TitleBox>
            <Title2 color={"#fff"}>{acf.oferta_tytul}</Title2>
          </TitleBox>
        </FlexBox>
      </FullWidthContainer>
      <Container>
        <FlexBox direction={"column"} justify={"center"} align="flex-end">
          <ContentBox
            direction={"column"}
            justify="space-between"
            alignLG={"flex-end"}
          >
            <Fade>
              <Textbox>
                <Text align={"justify"}>{parse(content)}</Text>
                <IconBox>
                  <Icon />
                </IconBox>
              </Textbox>
            </Fade>
            <ServiceList services={acf.usluga} />
          </ContentBox>
        </FlexBox>
      </Container>
    </SectionA>
  )
}

export default ServicesSection
